<template>
  <div class="announcement-page">
    <v-card color="#282F5B" :class="$vuetify.breakpoint.mdAndDown ? 'mobile_card' : 'desktop_card'">
      <v-card-title>
        <label class="title_color--text px-5 font-weight-bold text-left text-uppercase">{{ $t(`label.announcement`) }}</label>
      </v-card-title>
      <div>
        <v-data-table
          class="announcement-table px-4 py-4"
          :items="populatedAnnouncement"
          :items-per-page="this.pagination.tableRowsCount"
          hide-default-footer
          disable-sort
          :no-data-text="$t(`message.noDataAvaliable`)"
        >
          <template v-slot:item="i">
            <tr @click="openAnnouncementDialog(i.item)">
              <td class="body-2">{{ i.item.displayTime | longDate }}</td>
              <td class="body-2">{{ getAnnouncementTitle(i.item.titleLocale) }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-pagination class="py-4" v-model="pagination.currentPage" :length="pagination.totalPage" @input="changePage" :total-visible="pagination.paginationPageLimit"></v-pagination>
        </div>
      </div>
    </v-card>
    <app-dialog :dialogShow="annouoncementDialog.show" :max-width="550" :customClass="'px-3 py-3'" :title="$t(`label.message`)" :closeAction="annouoncementDialog.dialogXButton">
      <v-container class="fill-height">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text class="pa-0 pb-2 caption text-left">{{ annouoncementDialog.announcementDate | longDate }}</v-card-text>
            <v-card-text class="pa-0 pb-1 font-weight-bold title text-left">{{ annouoncementDialog.announcementTitle }}</v-card-text>
            <label class="pa-0 pb-2 body-1" v-html="annouoncementDialog.announcementMessage"></label>
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>
  </div>
</template>
<script>
import { ANNOUNCEMENT_LIST } from '@/store/announcement.module'
import { SHARED, CACHE_KEY } from '@/constants/constants'
import { AnnouncementType } from '@/constants/enums'
import { uiHelper, locale } from '@/util'

export default {
  name: 'announcement',
  metaInfo: {
    title: `${locale.getMessage('meta.announcement_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `${locale.getMessage('meta.announcement_description')}` },
      { name: 'keyword', content: `${locale.getMessage('meta.announcement_keyword')}` }
    ]
  },
  data: () => ({
    storageAnnouncement: uiHelper.getLocalStorage(`${CACHE_KEY.ANNOUNCEMENT}_${uiHelper.getPlatform()}`),
    currentLanguage: uiHelper.getLanguage(),
    populatedAnnouncement: [],
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 5
    },
    annouoncementDialog: {
      show: false,
      announcementTitle: '',
      announcementMessage: '',
      announcementDate: '',
      dialogXButton: () => {}
    },
    searchCriteria: {
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE
    }
  }),
  computed: {
    announcementList() {
      return this.$store.state.announcement.announcements
    }
  },
  watch: {
    announcementList() {
      this.storageAnnouncement = this.$store.state.announcement.announcements
      this.populateAnnouncement(this.searchCriteria.pageNumber)
    }
  },
  created() {
    this.getAnnouncementList()
  },
  methods: {
    getAnnouncementList() {
      if (this.storageAnnouncement == null) {
        let obj = {
          site: uiHelper.getPlatform(),
          type: AnnouncementType.ALL,
          page: '',
          itemsPerPage: ''
        }
        this.$store.dispatch(`${ANNOUNCEMENT_LIST}`, { obj })
      } else {
        this.populateAnnouncement(this.searchCriteria.pageNumber)
      }
    },
    populateAnnouncement(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      let d = this.storageAnnouncement
      this.populatedAnnouncement = d.slice((this.searchCriteria.pageNumber - 1) * 10, this.searchCriteria.pageNumber * 10)
      this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.pageSize)
    },
    changePage(targetPage) {
      this.populateAnnouncement(targetPage)
    },
    getAnnouncementTitle(announcementLocale) {
      if (announcementLocale.find(x => x.language == uiHelper.getLanguage()) != null) {
        return announcementLocale.find(x => x.language == uiHelper.getLanguage()).title
      } else if (announcementLocale.find(x => x.language == 'en') != null) {
        return announcementLocale.find(x => x.language == 'en').title
      } else {
        return announcementLocale[0].title
      }
    },
    openAnnouncementDialog(data) {
      this.annouoncementDialog.show = true
      this.annouoncementDialog.announcementDate = data.displayTime
      this.annouoncementDialog.dialogXButton = this.closeAnnouncementDialog

      try {
        this.annouoncementDialog.announcementTitle = data.titleLocale.find(x => x.language == this.currentLanguage).title
      } catch (error) {
        this.annouoncementDialog.announcementTitle = data.titleLocale.find(x => x.language == 'en').title
      }

      try {
        this.annouoncementDialog.announcementMessage = data.messageLocale.find(x => x.language == this.currentLanguage).message
      } catch (error) {
        this.annouoncementDialog.announcementMessage = data.messageLocale.find(x => x.language == 'en').message
      }
    },
    closeAnnouncementDialog() {
      this.annouoncementDialog = {
        show: false,
        announcementTitle: '',
        announcementMessage: '',
        announcementDate: '',
        dialogXButton: this.closeAnnouncementDialog
      }
    }
  }
}
</script>

<style lang="scss">
.mobile_card {
  margin-top: 0;
  border-radius: 20px;
}

.desktop_card {
  margin-top: 70px;
  border-radius: 10px;
}
.v-data-table-header,
.v-data-table__wrapper table .v-data-table-header tr {
  background-color: #2c3482 !important;
}
.announcement-table {
  background-color: transparent !important;
}

.announcement-table tr:nth-child(odd) {
  background-color: #1d223d !important;
}

.announcement-table tr:nth-child(even) {
  background-color: #2d3454 !important;
}

.announcement-page {
  min-height: 650px;
}

/* Annoouncement Table CSS */
.announcement-table {
  .v-data-table__wrapper {
    table {
      .v-data-table-header {
        tr {
          background-color: var(--v-base_3-base);
          th {
            span {
              color: var(--v-base-base);
            }
          }
        }
      }
      tbody {
        tr:nth-child(2n + 1) {
          background-color: var(--v-base_3-base);
        }
      }
      td,
      th {
        border-bottom: 0px !important;
      }
    }
  }
}
</style>
